import React from 'react';

export const OliveBranchRight = () => {
    return (
        <svg width="47px" height="51px" viewBox="0 0 47 51" version="1.1" className="branch branch-right">
            <title>Roman Holiday</title>
            <desc>An olive branch curving to the right</desc>
            <defs/>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="olivebranch2" stroke="#979797">
                    <path d="M17.6502497,37.9493115 C18.2855151,37.9493115 18.8004995,35.3506899 18.8004995,32.1451276 C18.8004995,30.0080861 18.4170829,28.0733581 17.6502497,26.3409437 C16.8834166,28.0733581 16.5,30.0080861 16.5,32.1451276 C16.5,35.3506899 17.0149843,37.9493115 17.6502497,37.9493115 Z" className="leaf" fill="#D8D8D8"/>
                    <path d="M22.6502497,31.9493115 C23.2855151,31.9493115 23.8004995,29.3506899 23.8004995,26.1451276 C23.8004995,24.0080861 23.4170829,22.0733581 22.6502497,20.3409437 C21.8834166,22.0733581 21.5,24.0080861 21.5,26.1451276 C21.5,29.3506899 22.0149843,31.9493115 22.6502497,31.9493115 Z" className="leaf" fill="#D8D8D8" transform="translate(22.650250, 26.145128) rotate(-8.000000) translate(-22.650250, -26.145128) "/>
                    <path d="M32.3017852,16 C32.9370506,16 33.452035,13.4013783 33.452035,10.1958161 C33.452035,8.05877459 33.0686184,6.12404662 32.3017852,4.39163218 C31.5349521,6.12404662 31.1515355,8.05877459 31.1515355,10.1958161 C31.1515355,13.4013783 31.6665199,16 32.3017852,16 Z" className="leaf" fill="#D8D8D8" transform="translate(32.301785, 10.195816) rotate(-7.000000) translate(-32.301785, -10.195816) "/>
                    <path d="M32.3017852,16 C32.9370506,16 33.452035,13.4013783 33.452035,10.1958161 C33.452035,8.05877459 33.0686184,6.12404662 32.3017852,4.39163218 C31.5349521,6.12404662 31.1515355,8.05877459 31.1515355,10.1958161 C31.1515355,13.4013783 31.6665199,16 32.3017852,16 Z" className="leaf" fill="#D8D8D8" transform="translate(32.301785, 10.195816) rotate(-7.000000) translate(-32.301785, -10.195816) "/>
                    <path d="M37.3017852,12 C37.9370506,12 38.452035,9.40137835 38.452035,6.19581609 C38.452035,4.05877459 38.0686184,2.12404662 37.3017852,0.391632181 C36.5349521,2.12404662 36.1515355,4.05877459 36.1515355,6.19581609 C36.1515355,9.40137835 36.6665199,12 37.3017852,12 Z" className="leaf" fill="#D8D8D8" transform="translate(37.301785, 6.195816) rotate(16.000000) translate(-37.301785, -6.195816) "/>
                    <path d="M16.5,52.7900377 C17.1352654,52.7900377 17.6502497,50.191416 17.6502497,46.9858538 C17.6502497,44.8488123 17.2668332,42.9140843 16.5,41.1816699 C15.7331668,42.9140843 15.3497503,44.8488123 15.3497503,46.9858538 C15.3497503,50.191416 15.8647346,52.7900377 16.5,52.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(16.500000, 46.985854) scale(1, -1) rotate(61.000000) translate(-16.500000, -46.985854) "/>
                    <path d="M34.5,37.7900377 C35.1352654,37.7900377 35.6502497,35.191416 35.6502497,31.9858538 C35.6502497,29.8488123 35.2668332,27.9140843 34.5,26.1816699 C33.7331668,27.9140843 33.3497503,29.8488123 33.3497503,31.9858538 C33.3497503,35.191416 33.8647346,37.7900377 34.5,37.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(34.500000, 31.985854) scale(1, -1) rotate(133.000000) translate(-34.500000, -31.985854) "/>
                    <path d="M25.3686819,23.7119657 C26.0039473,23.7119657 26.5189317,21.1133441 26.5189317,17.9077818 C26.5189317,15.7707403 26.1355151,13.8360123 25.3686819,12.1035979 C24.6018488,13.8360123 24.2184322,15.7707403 24.2184322,17.9077818 C24.2184322,21.1133441 24.7334166,23.7119657 25.3686819,23.7119657 Z" className="leaf" fill="#D8D8D8" transform="translate(25.368682, 17.907782) rotate(-18.000000) translate(-25.368682, -17.907782) "/>
                    <path d="M38.2144487,25.8482466 C38.8497141,25.8482466 39.3646984,23.2496249 39.3646984,20.0440626 C39.3646984,17.9070211 38.9812819,15.9722932 38.2144487,14.2398787 C37.4476156,15.9722932 37.064199,17.9070211 37.064199,20.0440626 C37.064199,23.2496249 37.5791833,25.8482466 38.2144487,25.8482466 Z" className="leaf" fill="#D8D8D8" transform="translate(38.214449, 20.044063) scale(1, -1) rotate(99.000000) translate(-38.214449, -20.044063) "/>
                    <path d="M1.55918171,47.0913353 C11.1945053,43.7566308 17.745073,40.4038105 21.2108848,37.0328743 C24.6766966,33.6619382 29.1254011,26.3165885 34.5569982,14.9968252 L35.3193139,12.1156161" className="branch"/>
                    <path d="M24.3155238,33.0419466 L33.7794942,27.2917467" className="branch"/>
                    <path d="M28.3527512,26.3409437 L29.9590726,16" className="branch"/>
                    <path d="M19.3292624,38.3970107 L33.3017852,34.8691681" className="branch"/>
                    <path d="M28.3017852,19 C28.9370506,19 29.452035,16.4013783 29.452035,13.1958161 C29.452035,11.0587746 29.0686184,9.12404662 28.3017852,7.39163218 C27.5349521,9.12404662 27.1515355,11.0587746 27.1515355,13.1958161 C27.1515355,16.4013783 27.6665199,19 28.3017852,19 Z" className="leaf" fill="#D8D8D8" transform="translate(28.301785, 13.195816) rotate(-13.000000) translate(-28.301785, -13.195816) "/>
                    <path d="M28.5,43.7900377 C29.1352654,43.7900377 29.6502497,41.191416 29.6502497,37.9858538 C29.6502497,35.8488123 29.2668332,33.9140843 28.5,32.1816699 C27.7331668,33.9140843 27.3497503,35.8488123 27.3497503,37.9858538 C27.3497503,41.191416 27.8647346,43.7900377 28.5,43.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(28.500000, 37.985854) scale(1, -1) rotate(61.000000) translate(-28.500000, -37.985854) "/>
                    <path d="M36.5,31.7900377 C37.1352654,31.7900377 37.6502497,29.191416 37.6502497,25.9858538 C37.6502497,23.8488123 37.2668332,21.9140843 36.5,20.1816699 C35.7331668,21.9140843 35.3497503,23.8488123 35.3497503,25.9858538 C35.3497503,29.191416 35.8647346,31.7900377 36.5,31.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(36.500000, 25.985854) scale(-1, 1) rotate(116.000000) translate(-36.500000, -25.985854) "/>
                </g>
            </g>
        </svg>
    )
}