import React from 'react';

export const OliveBranchLeft = () => {
    return (
        <svg width="51px" height="58px" viewBox="0 0 51 58" version="1.1" className="branch branch-left">
            <title>Roman Holiday</title>
            <desc>An olive branch curving to the left</desc>
            <defs/>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="olivebranch1" stroke="#979797">
                    <path d="M31.6502497,45.9493115 C32.2855151,45.9493115 32.8004995,43.3506899 32.8004995,40.1451276 C32.8004995,38.0080861 32.4170829,36.0733581 31.6502497,34.3409437 C30.8834166,36.0733581 30.5,38.0080861 30.5,40.1451276 C30.5,43.3506899 31.0149843,45.9493115 31.6502497,45.9493115 Z" className="leaf" fill="#D8D8D8"/>
                    <path d="M19.5060236,44.8570933 C20.1412889,44.8570933 20.6562733,42.2584717 20.6562733,39.0529094 C20.6562733,36.9158679 20.2728567,34.9811399 19.5060236,33.2487255 C18.7391904,34.9811399 18.3557738,36.9158679 18.3557738,39.0529094 C18.3557738,42.2584717 18.8707582,44.8570933 19.5060236,44.8570933 Z" className="leaf" fill="#D8D8D8" transform="translate(19.506024, 39.052909) rotate(-72.000000) translate(-19.506024, -39.052909) "/>
                    <path d="M35.3017852,49 C35.9370506,49 36.452035,46.4013783 36.452035,43.1958161 C36.452035,41.0587746 36.0686184,39.1240466 35.3017852,37.3916322 C34.5349521,39.1240466 34.1515355,41.0587746 34.1515355,43.1958161 C34.1515355,46.4013783 34.6665199,49 35.3017852,49 Z" className="leaf" fill="#D8D8D8" transform="translate(35.301785, 43.195816) rotate(-7.000000) translate(-35.301785, -43.195816) "/>
                    <path d="M15.5591817,55.0913353 C25.1945053,51.7566308 31.745073,48.4038105 35.2108848,45.0328743 C38.6766966,41.6619382 43.1254011,34.3165885 48.5569982,22.9968252 L49.3193139,20.1156161" className="branch" transform="translate(32.439248, 37.603476) scale(-1, 1) translate(-32.439248, -37.603476) "/>
                    <path d="M14.3017852,18 C14.9370506,18 15.452035,15.4013783 15.452035,12.1958161 C15.452035,10.0587746 15.0686184,8.12404662 14.3017852,6.39163218 C13.5349521,8.12404662 13.1515355,10.0587746 13.1515355,12.1958161 C13.1515355,15.4013783 13.6665199,18 14.3017852,18 Z" className="leaf" fill="#D8D8D8" transform="translate(14.301785, 12.195816) rotate(-22.000000) translate(-14.301785, -12.195816) "/>
                    <path d="M13.3017852,24 C13.9370506,24 14.452035,21.4013783 14.452035,18.1958161 C14.452035,16.0587746 14.0686184,14.1240466 13.3017852,12.3916322 C12.5349521,14.1240466 12.1515355,16.0587746 12.1515355,18.1958161 C12.1515355,21.4013783 12.6665199,24 13.3017852,24 Z" className="leaf" fill="#D8D8D8" transform="translate(13.301785, 18.195816) rotate(-38.000000) translate(-13.301785, -18.195816) "/>
                    <path d="M23.3017852,53 C23.9370506,53 24.452035,50.4013783 24.452035,47.1958161 C24.452035,45.0587746 24.0686184,43.1240466 23.3017852,41.3916322 C22.5349521,43.1240466 22.1515355,45.0587746 22.1515355,47.1958161 C22.1515355,50.4013783 22.6665199,53 23.3017852,53 Z" className="leaf" fill="#D8D8D8" transform="translate(23.301785, 47.195816) scale(-1, -1) rotate(79.000000) translate(-23.301785, -47.195816) "/>
                    <path d="M29.5,58.7900377 C30.1352654,58.7900377 30.6502497,56.191416 30.6502497,52.9858538 C30.6502497,50.8488123 30.2668332,48.9140843 29.5,47.1816699 C28.7331668,48.9140843 28.3497503,50.8488123 28.3497503,52.9858538 C28.3497503,56.191416 28.8647346,58.7900377 29.5,58.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(29.500000, 52.985854) scale(-1, -1) rotate(61.000000) translate(-29.500000, -52.985854) "/>
                    <path d="M43.2144487,50.8482466 C43.8497141,50.8482466 44.3646984,48.2496249 44.3646984,45.0440626 C44.3646984,42.9070211 43.9812819,40.9722932 43.2144487,39.2398787 C42.4476156,40.9722932 42.064199,42.9070211 42.064199,45.0440626 C42.064199,48.2496249 42.5791833,50.8482466 43.2144487,50.8482466 Z" className="leaf" fill="#D8D8D8" transform="translate(43.214449, 45.044063) scale(1, -1) rotate(167.000000) translate(-43.214449, -45.044063) "/>
                    <path d="M13.0360296,34.6584354 L22.5,28.9082355" className="branch" transform="translate(17.768015, 31.783335) scale(-1, 1) translate(-17.768015, -31.783335) "/>
                    <path d="M16.5591817,23.5367598 L18.1655032,13.1958161" className="branch"/>
                    <path d="M40.462669,51.8370894 L40.1683786,37.4290894" className="branch"/>
                    <path d="M19.3686819,13.7119657 C20.0039473,13.7119657 20.5189317,11.1133441 20.5189317,7.90778181 C20.5189317,5.77074031 20.1355151,3.83601234 19.3686819,2.1035979 C18.6018488,3.83601234 18.2184322,5.77074031 18.2184322,7.90778181 C18.2184322,11.1133441 18.7334166,13.7119657 19.3686819,13.7119657 Z" className="leaf" fill="#D8D8D8" transform="translate(19.368682, 7.907782) rotate(9.000000) translate(-19.368682, -7.907782) "/>
                    <path d="M22.3017852,29 C22.9370506,29 23.452035,26.4013783 23.452035,23.1958161 C23.452035,21.0587746 23.0686184,19.1240466 22.3017852,17.3916322 C21.5349521,19.1240466 21.1515355,21.0587746 21.1515355,23.1958161 C21.1515355,26.4013783 21.6665199,29 22.3017852,29 Z" className="leaf" fill="#D8D8D8" transform="translate(22.301785, 23.195816) scale(-1, 1) rotate(-13.000000) translate(-22.301785, -23.195816) "/>
                    <path d="M40.5,41.7900377 C41.1352654,41.7900377 41.6502497,39.191416 41.6502497,35.9858538 C41.6502497,33.8488123 41.2668332,31.9140843 40.5,30.1816699 C39.7331668,31.9140843 39.3497503,33.8488123 39.3497503,35.9858538 C39.3497503,39.191416 39.8647346,41.7900377 40.5,41.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(40.500000, 35.985854) scale(1, -1) rotate(182.000000) translate(-40.500000, -35.985854) "/>
                    <path d="M26.5,36.7900377 C27.1352654,36.7900377 27.6502497,34.191416 27.6502497,30.9858538 C27.6502497,28.8488123 27.2668332,26.9140843 26.5,25.1816699 C25.7331668,26.9140843 25.3497503,28.8488123 25.3497503,30.9858538 C25.3497503,34.191416 25.8647346,36.7900377 26.5,36.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(26.500000, 30.985854) rotate(20.000000) translate(-26.500000, -30.985854) "/>
                    <path d="M14.5,38.7900377 C15.1352654,38.7900377 15.6502497,36.191416 15.6502497,32.9858538 C15.6502497,30.8488123 15.2668332,28.9140843 14.5,27.1816699 C13.7331668,28.9140843 13.3497503,30.8488123 13.3497503,32.9858538 C13.3497503,36.191416 13.8647346,38.7900377 14.5,38.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(14.500000, 32.985854) scale(-1, 1) rotate(82.000000) translate(-14.500000, -32.985854) "/>
                    <path d="M8.5,33.7900377 C9.13526538,33.7900377 9.65024973,31.191416 9.65024973,27.9858538 C9.65024973,25.8488123 9.26683315,23.9140843 8.5,22.1816699 C7.73316685,23.9140843 7.34975027,25.8488123 7.34975027,27.9858538 C7.34975027,31.191416 7.86473462,33.7900377 8.5,33.7900377 Z" className="leaf" fill="#D8D8D8" transform="translate(8.500000, 27.985854) scale(-1, 1) rotate(82.000000) translate(-8.500000, -27.985854) "/>
                </g>
            </g>
        </svg>
    )
}